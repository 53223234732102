<template>
  <div class="public">
    <div class="public__wrapper">
      <div class="public__box">
        <a href="https://vepaar.com" class="">
          <img
            :src="$options.logo"
            width="240px"
            alt="Vepaar Logo"
            class="vp-mx-auto"
          />
        </a>

        <slot />

        <div class="vp-py-6 vp-border-t vp-grid vp-gap-4">
          <VpField
            v-if="['signup', 'forgot-password', 'help'].includes(action)"
            inline
            label="Have an account?"
          >
            <VyButton
              theme="link"
              :to="{ name: 'login' }"
              label="Login"
              class="button--primary"
            />
          </VpField>

          <VpField
            v-if="['login', 'help'].includes(action)"
            inline
            label="Don't have an account?"
          >
            <VyButton
              :to="{ name: 'signup' }"
              label="Create Account"
              class="button--primary"
            />
          </VpField>

          <VpField
            inline
            v-if="['login', 'help'].includes(action)"
            label="Forgot Password?"
          >
            <VyButton
              label="Reset"
              :to="{ name: 'forgot-password' }"
              class="button--primary"
            />
          </VpField>

          <VpField inline v-if="action != 'help'" label="Having issues?">
            <VyButton
              label="Get Help"
              href="https://vepa.ar/docs"
              class="button--primary button--right"
            />
          </VpField>

          <VpField label="Version" inline>
            <p class="vp-font-bold">{{ version }}</p>
          </VpField>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { version } from "../../../package.json";
import { VEPAAR_LOGO_LIGHT_MODE } from "@shared/assets/index.js";

export default {
  logo: VEPAAR_LOGO_LIGHT_MODE,

  props: {
    action: String,
  },

  data() {
    return {
      version,
    };
  },
};
</script>

<style>
.public {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.public__box {
  width: 300px;
  margin: auto;
}

.public__wrapper {
  padding: 64px;
}
</style>
