<template>
  <div>
    <Loader v-if="loading" />

    <TheShell v-else>
      <div v-if="showPasswordScreen">
        <p class="vp-mt-12 vp-text-center vp-mb-8">
          Set a super-strong password with characters and numbers!
        </p>

        <VpVueForm :save="setPassword" :has-reset="false" :fields="fields">
          <template #default>
            <VpField label="Password" rules="required|min:5" name="Password">
              <VyPassword
                v-model="password"
                type="password"
                ref="passwordInput"
                class="input--md input--rounded"
              />
            </VpField>
          </template>

          <template #footer>
            <VyButton
              label="Set Password"
              :loading="isLoading"
              type="submit"
              class="button--primary vp-mt-4 button--solid button--lg button--rounded vp-w-full"
            />
          </template>
        </VpVueForm>
      </div>
      <div v-else>
        <p class="vp-text-center vp-mt-12">
          {{ byName }} invited you as {{ roles }} at
        </p>
        <div class="vp-flex vp-items-center vp-justify-center">
          <img
            :src="photo"
            alt=""
            class="vp-m-3 vp-rounded-full vp-w-12 vp-h-12"
          />
          <p class="vp-font-bold vp-text-3xl">{{ businessName }}</p>
        </div>
        <div class="vp-border-t-2 vp-border-dashed">
          <h2 class="vp-font-bold vp-text-2xl vp-mt-12 vp-text-center">
            Hey, you're almost there.
          </h2>
          <p class="vp-mt-1 vp-text-center vp-mb-8">
            Press the down button to activate your official account and you're
            good to go!
          </p>
          <VyButton
            label="Accept Invitation"
            :loading="isLoading"
            @click.native="acceptInvite()"
            class="button--primary vp-mt-4 button--solid button--lg button--rounded vp-w-full"
          />
        </div>
      </div>
    </TheShell>
  </div>
</template>

<script>
import campaignUserByInvitationUuid from "@/graph/teams/campaignUserByInvitationUuid.gql";
import updateCampaignUserInvitationStatus from "@/graph/teams/updateCampaignUserInvitationStatus.gql";
import updateCampaignUserPassword from "@/graph/teams/updateCampaignUserPassword.gql";
import Loader from "components/src/app/loader.vue";
import TheShell from "./_shell.vue";
import { VEPAAR_LOGO_PLACEHOLDER } from "@shared/assets/index.js";

export default {
  components: {
    Loader,
    TheShell,
  },
  data() {
    return {
      invitationUuId: this.$route.params.invitationId,
      showPasswordScreen: null,
      password: "",
      fields: ["password"],
      byName: null,
      roles: null,
      businessName: null,
      businessLogo: null,
      isLoading: false,
      loading: false,
    };
  },
  mounted() {
    this.getUserData();
  },
  computed: {
    photo() {
      return this.businessLogo ? this.businessLogo : VEPAAR_LOGO_PLACEHOLDER;
    },
  },
  methods: {
    getUserData() {
      this.loading = true;
      this.$queryPublic(campaignUserByInvitationUuid, {
        uuid: this.invitationUuId,
      })
        .then(({ data }) => {
          const res = data.campaignUserByInvitationUuid;
          this.byName = res.byName;
          this.roles = res.roles.map((item) => item.name).join(", ");
          this.businessName = res.businessName;
          this.businessLogo = res.businessLogo?.url;
          if (res.isPasswordSet && res.status == "accepted") {
            this.$router.push({ name: "home" });
            this.$vayu.notify({
              title: "Invitation Accepted",
              message: "You've successfully joined the Business.",
              state: "success",
            });
          } else if (!res.isPasswordSet && res.status == "accepted") {
            this.showPasswordScreen = true;
          } else {
            this.showPasswordScreen = false;
          }
        })
        .catch((err) => {
          const errorMessage = err?.message;
          this.$vayu.notify({
            title: "Error!",
            message: errorMessage
              ? errorMessage
              : "Somthing went wrong, please try again",
            state: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    acceptInvite() {
      this.isLoading = true;
      const postData = {
        uuid: this.invitationUuId,
        status: "accepted",
      };
      this.$mutatePublic(updateCampaignUserInvitationStatus, postData)
        .then(({ data }) => {
          if (data.updateCampaignUserInvitationStatus.isPasswordSet) {
            this.$router.push({
              name: "home",
              params: {
                campaignId: data.updateCampaignUserInvitationStatus.campaignId,
                userId: data.updateCampaignUserInvitationStatus.userId,
              },
            });
            this.$track("Invitation accepted", {
              "Campaign ID": data.updateCampaignUserInvitationStatus.campaignId,
              "Redirection Url":
                data.updateCampaignUserInvitationStatus.redirectionUrl,
              "User ID": data.updateCampaignUserInvitationStatus.userId,
              UUID: data.updateCampaignUserInvitationStatus.uuid,
            });
            this.$vayu.notify({
              title: "Invitation Accepted",
              message: "You've successfully joined the Business.",
              state: "success",
            });
          } else {
            this.showPasswordScreen = true;
          }
        })
        .catch((err) => {
          const errorMessage = err?.message;
          this.$vayu.notify({
            title: "Error!",
            message: errorMessage
              ? errorMessage
              : "Somthing went wrong, please try again",
            state: "danger",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setPassword() {
      this.isLoading = true;
      const postdata = {
        invitationUuid: this.invitationUuId,
        invitationStatus: "accepted",
        password: this.password,
      };
      if (this.password) {
        this.$mutatePublic(updateCampaignUserPassword, postdata)
          .then(({ data }) => {
            this.$vayu.notify({
              title: "Your account has been created",
              message: "Thank you for signing up. Welcome To Vepaar!",
              state: "success",
            });
            this.$router.push({
              name: "home",
              params: {
                campaignId: data.updateCampaignUserPassword.campaignId,
                userId: data.updateCampaignUserPassword.userId,
              },
            });
          })
          .catch((err) => {
            const errorMessage = err?.message;
            this.$vayu.notify({
              title: "Error!",
              message: errorMessage
                ? errorMessage
                : "Somthing went wrong, please try again",
              state: "danger",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
