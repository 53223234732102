<template>
  <TheShell>
    <div class="vp-pb-6">
      <validation-observer v-slot="{ invalid }">
        <div class="vp-mt-2 vp-mb-18 vp-py-2 vp-border-t vp-text-start">
          <p
            class="vp-text-gray-500 vp-leading-none vp-mb-1 vp-tracking-widest vp-text-xs"
          >
            WITH
          </p>
          <img class="vp-m-auto" width="100px" :src="$options.logo" alt="" />
        </div>

        <!-- NEW ACCOUNT -->
        <div v-if="isNew">
          <div class="vp-mb-4">
            <h4 class="vp-text-gray-500 vp-leading-none vp-font-bold">
              🙏 Welcome Sumo-ling!
            </h4>
            <p class="vp-text-gray-500 vp-text-xs">Let's setup your account.</p>
          </div>
          <form @submit.prevent="setPassword()" class="vp-gap-4">
            <VpField label="Email">
              <h5 class="vp-leading-none vp-font-bold">{{ form.email }}</h5>
            </VpField>

            <VpField label="Name" name="Name" rules="required">
              <VpInput>
                <VpTextBox ref="focus" v-model="form.name" />
              </VpInput>
            </VpField>

            <VpField label="Set Password" name="Set Password" rules="required">
              <VyPassword
                type="password"
                class="input--md input--rounded"
                autocomplete="new-password"
                v-model="form.new_password"
                placeholder="Password"
              />
            </VpField>

            <VyButton
              :disabled="invalid"
              :loading="loading"
              type="submit"
              :icon="$options.icons.ArrowRight"
              label="Continue"
              class="button--primary button--solid button--md button--right button--rounded"
            />
          </form>
        </div>

        <!-- EXISTING ACCOUNT -->
        <div v-else>
          <div class="vp-mb-4">
            <h4
              class="vp-text-gray-500 vp-leading-none vp-text-lg vp-font-bold"
            >
              🙏 Welcome Sumo-ling!
            </h4>
            <p class="vp-mt-2">
              Your account is activated with the AppSumo Lifetime Plan 🎉.
            </p>

            <VyButton
              :icon="$options.icons.ArrowRight"
              label="Continue"
              @click.native="login()"
              class="vp-mt-4 button--primary button--solid button--md button--right button--rounded"
            />
          </div>
        </div>
      </validation-observer>
    </div>
  </TheShell>
</template>

<script>
import { ArrowRight } from "icons/icons.js";
import { setToken } from "plugins/utils";
import TheShell from "./_shell.vue";
import { APPSUMO_LOGO } from "@shared/assets/index.js";

export default {
  logo: APPSUMO_LOGO,
  components: {
    TheShell,
  },
  icons: {
    ArrowRight,
  },

  data() {
    return {
      error: null,
      loading: false,
      form: {
        name: null,
        uuid: this.$route.query.uuid,
        email: this.$route.query.email,
        new_password: null,
      },
    };
  },

  computed: {
    isNew() {
      return this.$route.query.new == 1;
    },
  },

  mounted() {
    this.$refs.focus?.$el.focus();
  },

  methods: {
    async setPassword() {
      this.error = null;
      this.loading = true;

      fetch("https://api.vepaar.com/api/v1/appsumo-onboarding", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(this.form),
      })
        .then((res) => res.json())
        .then((res) => {
          setToken(res.token);

          this.$router.push({
            name: "subscription",
          });

          this.$vayu.notify({
            title: "Account Created.",
            message:
              "Your account is activated with the AppSumo Lifetime Plan 🎉.",
            state: "success",
            duration: 5000,
          });
        })
        .catch((err) => {
          console.error(err);
          this.error = err.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async login() {
      this.$router.push({
        name: "subscription",
      });
    },
  },
};
</script>
